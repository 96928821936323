import React from 'react';
import styled from 'styled-components';

import { Img } from './common/Img.style';
import { H5 } from './common/H.style';
import { P } from './common/P.style';
import Link from './Link';

import bana from '../assets/img/telemed-link_2.jpg';

const Text = styled.p`
font-size: 16px;
`;

const TelemedLink = () => (
  <div css={`
      margin: 5em 0;
    `}
    >
      <Text>
        <H5>オンライン診療について</H5>
       <P>
       新型コロナウイルス感染拡大に伴って、オンライン診療の規制が一時的に緩められており、初診でも薬が処方できるようになっています。<br />
        ただ、紹介状などがない限り、７日分しか処方ができない規則となっています。<br />
        実際には、１週間ごとに薬の変更を検討した方がよいことが多いため、あまり問題になることはないように感じています。<br />
         薬は自宅近くの薬局で受け取れるため、物理的な距離が問題にならないのはとても良い点です。<br />
         当院でも、全国の微熱の患者さんをオンライン診療で診察しています。
        </P>
        <P css={`
              margin: 1.5em auto 0;
              text-align: center;
              max-width: 680px;
          `}
          >
          <Link to="https://telemed-app.hirahata-clinic.or.jp/?locate=shibuya" external>
            <P>
              当院のオンライン診療をご利用を希望される方はこちらからお申し込みください。
            </P>
            <Img src={bana} />
          </Link>
        </P>
    </Text>
  </div>
);



export default TelemedLink;