import React from 'react';
import { graphql } from 'gatsby';
import rehypeReact from 'rehype-react';
import styled from 'styled-components';

import SubLayout from '../components/SubLayout';
import { Img } from '../components/common/Img.style';
import ArticleTemplate from '../components/ArticleTemplate';
import TelemedLink from '../components/TelemedLink';

import res1lg from '../assets/img/res-1-lg.png';

const p_style = styled.p`
  margin: 2em 1.5em;
  line-height: 1.88;
`;
const h4_style = styled.h4`
  margin-top: 2em;
  padding-left: 1em;
  background-color: #666;
  color: #fff;
  font-size: 1.25em;
  font-weight: 600;
`;

const h5_style = styled.h5`
  font-size: 1.25em;
  font-weight: 500;
`;

const li_style = styled.li`
  padding-bottom: 0.75em;
`;

const renderAst = new rehypeReact({
  createElement: React.createElement,
  components: {
    p: p_style,
    h4: h4_style,
    h5: h5_style,
    li: li_style,
  },
}).Compiler;

const Covid19Template = ({
  data, // this prop will be injected by the GraphQL query below.
  pageContext,
}) => {
  const { markdownRemark } = data; // data.markdownRemark holds your post data
  const { frontmatter, htmlAst } = markdownRemark;
  const { title, date } = frontmatter;
  const { previous, next } = pageContext;
  return (
    <SubLayout topTitle="新型コロナ感染症・後遺症の記事">
      <Img src={res1lg} />
      <div>
        <ArticleTemplate
          topLink="/covid19"
          date={date}
          title={title}
          previous={previous}
          next={next}
          htmlAst={renderAst(htmlAst)}
        />
      </div>
      <TelemedLink></TelemedLink>
    </SubLayout>
  );
};

export default Covid19Template;

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      htmlAst
      frontmatter {
        date(formatString: "YYYY/MM/DD")
        title
      }
    }
  }
`;
